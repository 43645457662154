
/**
 * Module depenencies.
 */

import { BlocksHome } from 'src/types/homepage';
import { Box, RawHtml, Type, media, units, useBreakpoint } from '@untile/react-components';
import { Display } from 'src/components/core/typography';
import { ifProp, theme } from 'styled-tools';
import { useRouter } from 'next/router';
import AnimatedNumber from 'react-animated-number';
import Container from 'src/components/core/layout/container';
import FadeInAnimation from 'src/components/core/animations/fade-in';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import React, { ReactElement, useEffect, useState } from 'react';
import VideoPlayer from 'src/components/video-player';
import map from 'lodash/map';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  blocks: BlocksHome[],
  canTrigger: boolean,
  className?: string,
  isAnimated: boolean,
  isVisible: boolean
};

/**
 * `Wrapper` component.
 */

const Wrapper = styled.div`
  position: relative;

  ${media.min('md')`
    height: calc(100vh - ${theme('dimensions.navbarHeight')}px);
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-gap: ${units(5)};

  ${media.min('md')`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `Block` styled component.
 */

const Block = styled.div`
  ${media.min('ms')`
    display: grid;
    grid-template-areas:
      '. video       .'
      '. title       .'
      '. title       .'
      '. description .';
    grid-template-columns: 2fr 8fr 2fr;
    grid-template-rows: max-content 22px repeat(2, max-content);
  `}

  ${media.min('md')`
    grid-template-areas:
      'video       .'
      'title       title'
      'title       title'
      'description description';
    grid-template-columns: 10fr 2fr;
    grid-template-rows: max-content 38px repeat(2, max-content);
  `}

  ${media.min('xxl')`
    grid-template-areas:
      'video       .'
      'title       .'
      'title       .'
      'description .';
  `}
`;

/**
 * `StyledVideoPlayer` styled component.
 */

const StyledVideoPlayer = styled(VideoPlayer)`
  opacity: 0.25;
  padding-top: 55%;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Display)<{ isNumbersAnimated: boolean }>`
  align-items: center;
  display: flex;
  position: relative;

  ${media.min('ms')`
    opacity: 0;
    transition: opacity ${theme('animations.defaultTransition')};
  `}

  span {
    ${media.min('xl')`
      font-size: 50px;
      letter-spacing: 6.25px;
    `}
  }

  ${ifProp('isNumbersAnimated', css`
    ${media.min('ms')`
      opacity: 1;
    `}
  `)}
`;

/**
 * `NumbersSection` component.
 */

const NumbersSection = (props: Props): ReactElement | null => {
  const { blocks, canTrigger, className, isAnimated, isVisible } = props;
  const { locale } = useRouter();
  const isMobile = useBreakpoint('ms', 'max');
  const [isNumbersAnimated, setIsNumbersAnimated] = useState<boolean>(false);

  useEffect(() => {
    if (!canTrigger) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsNumbersAnimated(true);
    }, 800);

    return () => clearTimeout(timeout);
  }, [canTrigger]);

  return (
    <Wrapper className={className}>
      <Container>
        <Grid>
          {map(blocks, ({
            description,
            prefix,
            smallDescription,
            suffix,
            title,
            video
          }: BlocksHome, index: number) => (
            <Block key={index}>
              <Box
                gridAreaMs={'video'}
                gridRowMs={'1 / 3'}
              >
                <FadeInUpAnimation
                  canTrigger={canTrigger}
                  isAnimated={isAnimated}
                  isVisible={isVisible}
                  options={{
                    distance: '100vh',
                    transitionDelay: 0.5,
                    transitionTime: 2
                  }}
                >
                  <StyledVideoPlayer
                    controls={false}
                    hasCloseButton={false}
                    loop
                    playing
                    url={video}
                  />
                </FadeInUpAnimation>
              </Box>

              <Box
                gridAreaMs={'title'}
                gridRowMs={'2 / 4'}
              >
                <FadeInUpAnimation
                  canTrigger={canTrigger}
                  isAnimated={isAnimated}
                  isVisible={isVisible}
                  options={{
                    distance: '100vh',
                    transitionDelay: 0.5,
                    transitionTime: 2
                  }}
                >
                  <Title isNumbersAnimated={isNumbersAnimated}>
                    {prefix && (
                      <RawHtml>
                        {prefix}
                      </RawHtml>
                    )}

                    {isNumbersAnimated && !isMobile ? (
                      <AnimatedNumber
                        duration={3000}
                        formatValue={number => number.toLocaleString(locale)}
                        stepPrecision={0}
                        value={parseFloat(title)}
                      />
                    ) : parseFloat(title).toLocaleString(locale)}

                    {suffix && (
                      <RawHtml>
                        {suffix}
                      </RawHtml>
                    )}
                  </Title>
                </FadeInUpAnimation>
              </Box>

              <Box
                gridAreaMs={'description'}
                gridRowMs={'4 / 5'}
              >
                <FadeInAnimation
                  canTrigger={canTrigger}
                  isAnimated={isAnimated}
                  isVisible={isVisible}
                  options={{
                    transitionDelay: 2,
                    transitionTime: 3
                  }}
                >
                  <Type.Paragraph size={'large'}>
                    {description}
                  </Type.Paragraph>

                  {smallDescription && (
                    <Type.Paragraph size={'small'}>
                      {smallDescription}
                    </Type.Paragraph>
                  )}
                </FadeInAnimation>
              </Box>
            </Block>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};

/**
 * Export `NumbersSection` component.
 */

export default NumbersSection;


/**
 * Module depenencies.
 */

import { Clients } from 'src/types/clients';
import { Slide } from 'src/components/core/slider';
import { Type, color, isExternalUrl, units } from '@untile/react-components';
import { theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Carousel from 'src/components/core/carousel';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import React, { ReactElement, useRef, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import map from 'lodash/map';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  clients: Clients[]
};

/**
 * `Wrapper` component.
 */

const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;

  .swiper-slide {
    width: 130px;
  }
`;

/**
 * `VisibleContainer` styled component.
 */

const VisibleContainer = styled(Container)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @media only screen and (min-width: 2000px) {
    display: none;
  }
`;

/**
 * `VisibleWrapper` styled component.
 */

const VisibleWrapper = styled.div`
  height: 100%;
  position: relative;

  &::after,
  &::before {
    content: '';
    height: 100%;
    position: absolute;
    width: calc((100vw - ${theme('breakpoints.md')}px) / 2);
    z-index: 2;
  }

  &::after {
    background: linear-gradient(to left, ${color.transparentize('white', 0.4)} 75%, ${color.transparentize('white', 0)} 100%);
    left: 100%;
  }

  &::before {
    background: linear-gradient(to right, ${color.transparentize('white', 0.4)} 75%, ${color.transparentize('white', 0)} 100%);
    right: 100%;
  }
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  transition: transform 2s ease;
`;

/**
 * `SlideLink` styled component.
 */

const SlideLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalUrl(href) && RouterLink || 'a'
}))`
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover {
    ${StyledImage} {
      transform: scale(1.1);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  height: 60px;
  overflow: hidden;
  position: relative;
`;

/**
 * Carousel config.
 */

const carouselConfig = {
  autoplay: {
    delay: 500
  },
  breakpoints: {
    1920: {
      centerSlides: true,
      loop: false,
      loopedSlides: 0
    }
  },
  loop: true,
  loopedSlides: 25,
  slidesPerView: 'auto'
};

/**
 * `ClientsSection` component.
 */

const ClientsSection = (props: Props): ReactElement | null => {
  const { className, clients } = props;
  const { translate } = useTranslate();
  const { locale } = useRouter();
  const sliderRef = useRef<any>();
  const [activeSlide, setActiveSlide] = useState<number>(0);

  return (
    <Wrapper className={className}>
      <VisibleContainer>
        <VisibleWrapper />
      </VisibleContainer>

      <Container>
        <Type.Paragraph
          marginBottom={units(1.5)}
          size={'medium'}
        >
          {translate('common:homepage.sectionClients.title')}
        </Type.Paragraph>
      </Container>

      <Carousel
        activeSlide={activeSlide}
        autoplay
        carouselConfig={carouselConfig}
        containerModifierClass={'clients-slider'}
        onSetActiveSlide={setActiveSlide}
        ref={sliderRef}
        speed={1000}
      >
        {map(clients, ({ image, link }: Clients, index: number) => {
          return (
            <Slide key={index}>
              {link ? (
                <SlideLink
                  href={link}
                  {...link && !isExternalUrl(link) ? { locale } : {
                    rel: 'noopener',
                    target: '_blank'
                  }}
                >
                  <ImageWrapper>
                    <StyledImage
                      alt={translate('common:homepage.sectionClients.client')}
                      layout={'fill'}
                      objectFit={'contain'}
                      src={image}
                    />
                  </ImageWrapper>
                </SlideLink>
              ) : (
                <>
                  <ImageWrapper>
                    <Image
                      alt={translate('common:homepage.sectionClients.client')}
                      layout={'fill'}
                      objectFit={'contain'}
                      src={image}
                    />
                  </ImageWrapper>
                </>
              )}
            </Slide>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

/**
 * Export `ClientsSection` component.
 */

export default ClientsSection;


/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchHomepageProjects } from './fetch-homepage-projects';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  initialData?: P | null
};

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-homepage-projects`;
};

/**
 * `useHomepageProjects` hook.
 */

function useHomepageProjects<P>({ initialData }: Options<P>): Props<P> {
  const { locale } = useRouter();
  const variables = useMemo(() => ({ locale }), [locale]);
  const result = useQuery([actionType(variables), variables], () => {
    return fetchHomepageProjects(variables);
  }, {
    initialData
  });

  return result;
}

/**
 * Export `useHomepageProjects` hook.
 */

export default useHomepageProjects;

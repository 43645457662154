
/**
 * Module dependencies.
 */

import { HomepageProjects } from 'src/types/homepage-projects';
import { media, units, useBreakpoint } from '@untile/react-components';
import { theme } from 'styled-tools';
import CarouselBox from 'src/components/core/globe/carousel-box';
import Container from 'src/components/core/layout/container';
import Globe from 'src/components/core/globe';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import map from 'lodash/map';
import styled from 'styled-components';
import useGlobe3d from 'src/hooks/use-globe-3d';

/**
 * `Props` type.
 */

type Props = {
  canTrigger: boolean,
  className?: string,
  projectsLocations: HomepageProjects[]
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  ${media.min('md')`
    height: calc(100vh - ${theme('dimensions.navbarHeight')}px);
    padding-top: ${units(8)};
    overflow: hidden;
  `}

  ${media.max('ms')`
    padding: ${units(8)} 0;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  max-width: ${units(100)};

  ${media.min('xxl')`
    max-width: ${units(156)};
  `}
`;

/**
 * `GlobeWrapper` styled component.
 */

const GlobeWrapper = styled.div`
  ${media.min('md')`
    height: 100%;
    min-height: calc(100vh - ${theme('dimensions.navbarHeightMobile')}px);
  `}
`;

/**
 * `StyledGlobe` styled component.
 */

const StyledGlobe = styled(Globe)`
  height: 100%;
  margin: 0 auto;
  max-width: 96%;
  width: 100%;

  ${media.min('xl')`
    max-width: 100%;
  `}
`;

/**
 * `GlobeSection` component.
 */

const GlobeSection = (props: Props): ReactElement => {
  const { canTrigger, className, projectsLocations } = props;
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const isMobile = useBreakpoint('md', 'max');
  const markers = useMemo(() => {
    return map(projectsLocations, ({
      latitude,
      longitude,
      title,
      ...rest
    }: HomepageProjects) => ({
      location: {
        lat: latitude,
        lng: longitude
      },
      name: title,
      ...rest
    }));
  }, [projectsLocations]);

  const {
    activeMarkerIndex,
    element,
    instance
  } = useGlobe3d({
    hasInfoBox: true,
    markers
  });

  useEffect(() => {
    if (instance && canTrigger && !isAnimated && !isMobile) {
      instance.zoom = 0.25;
      instance.animate('zoom', 1.25, {
        duration: 1500,
        easing: 'in-out-cubic'
      });

      setIsAnimated(true);
    }
  }, [canTrigger, instance, isAnimated, isMobile]);

  return (
    <Wrapper className={className}>
      <StyledContainer>
        <GlobeWrapper id={'globe-wrapper'}>
          <StyledGlobe ref={element} />
        </GlobeWrapper>
      </StyledContainer>

      {activeMarkerIndex >= 0 && (
        <CarouselBox
          id={markers[activeMarkerIndex]?.id}
          index={activeMarkerIndex}
          title={markers[activeMarkerIndex]?.name}
        />
      )}
    </Wrapper>
  );
};

/**
 * Export `GlobeSection` component.
 */

export default GlobeSection;

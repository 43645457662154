
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchBanner } from './fetch-banner';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  initialData?: P | null
};

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-banners`;
};

/**
 * `useBanner` hook.
 */

function useBanner<P>(options: Options<P>): Props<P> {
  const { ...rest } = options;
  const { locale } = useRouter();
  const variables = useMemo(() => ({ locale }), [locale]);

  const result = useQuery([actionType(variables), variables], () => {
    return fetchBanner(variables);
  }, { ...rest });

  return result;
}

/**
 * Export `useBanner` hook.
 */

export default useBanner;

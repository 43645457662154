
/**
 * Module dependencies.
 */

import { Svg, color, media, units } from '@untile/react-components';
import { ifProp } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import closeIcon from 'src/assets/svg/close-big.svg';
import playIcon from 'src/assets/svg/play.svg';
import styled, { css } from 'styled-components';

/**
 * Export `VideoPlayerProps` interface.
 */

export interface VideoPlayerProps extends ReactPlayerProps {
  className?: string;
  hasCloseButton?: boolean;
  image?: string | null;
  isDetail?: boolean;
  loop?: boolean;
  playing?: boolean;
  stopOnUnmount?: boolean;
  url: string;
  volume: number;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ isDetail?: boolean }>`
  position: relative;
  width: 100%;

  ${ifProp('isDetail', css`
    padding-top: 67%;
  `, css`
    ${media.max('md')`
      padding-top: 56.9%;
    `}
  `)}
`;

/**
 * `StyledReactPlayer` styled component.
 */

const StyledReactPlayer = styled(ReactPlayer)`
  left: 0;
  position: absolute;
  top: 0;
`;

/**
 * `PlaySvg` styled component.
 */

const PlaySvg = styled(Svg).attrs({
  icon: playIcon,
  size: units(4)
})`
  color: ${color('white')};
  transition: transform 0.3s ease;
`;

/**
 * `CloseSvg` styled component.
 */

const CloseSvg = styled(Svg).attrs({
  icon: closeIcon,
  size: units(3)
})`
  color: ${color('white')};
  transition: transform 0.3s ease;
`;

/**
 * `PlayButton` styled component.
 */

const PlayButton = styled.button<{ isDetail?: boolean }>`
  align-items: center;
  appearance: none;
  background-color: ${color.transparentize('white', 0)};
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  height: ${units(8)};
  justify-content: center;
  left: 0;
  padding: 0;
  position: absolute;
  top: ${units(1)};
  transition: transform 0.3s ease;
  width: ${units(8)};

  ${ifProp('isDetail', css`
    left:  ${units(1)};
    top: ${units(2)};
  `, css`
    left: 0;
    top: ${units(1)};

    ${media.min('md')`
      left: -${units(1)};
      top: ${units(4)};
    `}
  `)}

  &:hover {
    ${PlaySvg} {
      transform: scale(1.2);
    }
  }
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(PlayButton)<{ isDetail?: boolean }>`
  background-color: ${color.transparentize('grey500', 0.2)};
  height: ${units(5)};
  left: auto;
  pointer-events: auto;
  right: 0;
  width: ${units(5)};

  ${media.min('md')`
    height: ${units(6)};
    right: 0;
    width: ${units(6)};
  `}

  ${ifProp('isDetail', css`
    right: ${units(0.5)};
    top: ${units(0.5)};

    ${media.min('md')`
      right: ${units(3)};
      top: ${units(3)};
    `}
  `)}

  &:hover {
    ${CloseSvg} {
      transform: scale(1.2);
    }
  }
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

/**
 * `PlayVideoButton` component.
 */

export const PlayVideoButton = ({ isDetail }): ReactElement => (
  <StyledContainer>
    <PlayButton
      aria-label={'Play video'}
      isDetail={isDetail}
    >
      <PlaySvg />
    </PlayButton>
  </StyledContainer>
);

/**
 * Export `VideoPlayer` component.
 */

export const VideoPlayer = (props: VideoPlayerProps): ReactElement => {
  const {
    className,
    controls,
    hasCloseButton = true,
    image,
    isDetail,
    url,
    volume,
    ...rest
  } = props;

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>();
  const videoRef = useRef();
  const handleAutoplay = useCallback(() => {
    if (wrapperRef && wrapperRef.current) {
      const videoElement = wrapperRef.current.firstChild.firstChild as HTMLVideoElement;

      videoElement.setAttribute('data-autoplay', 'true');
      setIsPlaying(true);
    }
  }, []);

  const handleVideoEnd = useCallback(() => {
    if (videoRef && videoRef.current) {
      // @ts-ignore
      videoRef.current.showPreview();
      setIsPlaying(false);
    }
  }, []);

  return (
    <Wrapper
      className={className}
      isDetail={isDetail}
      ref={wrapperRef}
    >
      <StyledReactPlayer
        {...rest}
        config={{
          attributes: {
            controlsList: 'nodownload noplaybackrate',
            disablePictureInPicture: 'false'
          }
        }}
        {...image && {
          light: image,
          playIcon: <PlayVideoButton isDetail={isDetail} />
        }}
        controls={controls ?? true}
        height={'100%'}
        onEnded={handleVideoEnd}
        onReady={handleAutoplay}
        ref={videoRef}
        url={url}
        volume={volume ?? 0}
        width={'100%'}
      />

      {isPlaying && hasCloseButton && (
        <StyledContainer>
          <CloseButton
            isDetail={isDetail}
            onClick={handleVideoEnd}
          >
            <CloseSvg />
          </CloseButton>
        </StyledContainer>
      )}
    </Wrapper>
  );
};

/**
 * Export `VideoPlayer` component.
 */

export default VideoPlayer;


/**
 * Module dependencies.
 */

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { HomepageProjectDetail, ProjectImage } from 'src/types/homepage-projects';
import { Slide } from 'src/components/core/slider';
import { Subtitle } from 'src/components/core/typography';
import {
  color,
  isExternalUrl,
  media,
  units,
  useBreakpoint
} from '@untile/react-components';

import { theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Carousel from 'src/components/core/carousel';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import Loading from 'src/components/loading';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import SliderNavigation from 'src/components/core/slider/slider-navigation';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import styled from 'styled-components';
import useHomepageProjectDetail from 'src/api/homepage/use-homepage-project-detail';

/**
 * Timeout.
 */

const timeout = 300;

/**
 * `Props` type.
 */

type Props = {
  id: string,
  index: number,
  title: string
};

/**
 * `Title` styled component.
 */

const Title = styled(Subtitle)`
  bottom: 100px;
  left: ${units(2)};
  position: absolute;
  transform: translateY(calc(-100% - ${units(1)}));

  ${media.min('lg')`
    left: calc(((100vw - 992px) / 2) + ${units(3)} - ${units(4)});
  `}

  ${media.min('xxl')`
    left: calc(((100vw - 1440px) / 2) + ${units(3)} - ${units(4)});
  `}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  bottom: 0;
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1001;

  ${media.min('ms')`
    height: 120px;
  `}

  .swiper-container {
    padding: 0 calc(((100vw - 992px) / 2) + ${units(3)} - ${units(4.5)});

    ${media.min('xxl')`
      padding: 0 calc(((100vw - 1440px) / 2) + ${units(3)} - ${units(4.5)});
    `}
  }

  &.carousel-box-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  &.carousel-box-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.carousel-box-enter-active,
  &.carousel-box-enter-done {
    opacity: 1;
    transform: translateY(0);
  }

  &.carousel-box-exit-active {
    opacity: 0;
    transform: translateY(100%);
  }

  &.carousel-box-enter-active,
  &.carousel-box-exit-active {
    transition: ${theme('animations.easeOutQuadTransition')};
    transition-duration: ${timeout}ms;
    transition-property: opacity, transform;
  }
`;

/**
 * `VisibleContainer` styled component.
 */

const VisibleContainer = styled(Container)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

/**
 * `VisibleWrapper` styled component.
 */

const VisibleWrapper = styled.div`
  height: 100%;
  position: relative;

  &::after,
  &::before {
    background-color: ${color.transparentize('white', 0.9)};
    content: '';
    height: 100%;
    position: absolute;
    width: calc((100vw - ${theme('breakpoints.md')}px) / 2);
    z-index: 2;
  }

  &::after {
    left: calc(100% + 10px);
  }

  &::before {
    right: calc(100% + 10px);
  }
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  transition: transform 2s ease;
`;

/**
 * `SlideLink` styled component.
 */

const SlideLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalUrl(href) && RouterLink || 'a'
}))`
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover {
    ${StyledImage} {
      transform: scale(1.1);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

/**
 * `PreviousButtonWrapper` styled component.
 */

const PreviousButtonWrapper = styled.div`
  display: flex;
  left: ${units(5)};
  padding: ${units(1)};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `NextButtonWrapper` styled component.
 */

const NextButtonWrapper = styled(PreviousButtonWrapper)`
  left: auto;
  right: ${units(5)};
`;

/**
 * Carousel config.
 */

const carouselConfig = {
  breakpoints: {
    768: {
      slidesPerView: 4
    },
    992: {
      slidesPerView: 6
    }
  },
  slidesPerView: 3
};

/**
 * `CarouselBox` component.
 */

const CarouselBox = (props: Props): ReactElement => {
  const { id, index, title, ...rest } = props;
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const sliderRef = useRef<any>();
  const { locale } = useRouter();
  const isMobile = useBreakpoint('md', 'max');
  const {
    data,
    isLoading,
    isSuccess
  } = useHomepageProjectDetail<HomepageProjectDetail>({ id });

  const total = size(data?.images);
  const hasArrowsNavigation = total > 6 && !isMobile;
  const handlePrevious = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  }, []);

  const handleNext = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  }, []);

  return (
    <SwitchTransition>
      <CSSTransition
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}
        classNames={'carousel-box'}
        key={index}
        timeout={timeout}
      >
        <>
          {isSuccess && (
            <Wrapper>
              <Loading active={isLoading} />

              <VisibleContainer>
                <VisibleWrapper />
              </VisibleContainer>

              <Title>
                {title}
              </Title>

              {!isEmpty(data?.images) && (
                <Carousel
                  activeSlide={activeSlide}
                  carouselConfig={carouselConfig}
                  containerModifierClass={'homepage-projects-slider'}
                  onSetActiveSlide={setActiveSlide}
                  ref={sliderRef}
                  speed={1000}
                  {...rest}
                >
                  {map(data?.images, ({ url, urlImage }: ProjectImage, index: number) => {
                    return (
                      <Slide key={index}>
                        {url ? (
                          <SlideLink
                            href={url}
                            {...url && !isExternalUrl(url) ? { locale } : {
                              rel: 'noopener',
                              target: '_blank'
                            }}
                          >
                            <ImageWrapper>
                              <StyledImage
                                alt={title}
                                layout={'fill'}
                                objectFit={'cover'}
                                src={urlImage}
                              />
                            </ImageWrapper>
                          </SlideLink>
                        ) : (
                          <>
                            <ImageWrapper>
                              <Image
                                alt={title}
                                layout={'fill'}
                                objectFit={'cover'}
                                src={urlImage}
                              />
                            </ImageWrapper>
                          </>
                        )}
                      </Slide>
                    );
                  })}
                </Carousel>
              )}

              {hasArrowsNavigation && (
                <>
                  <PreviousButtonWrapper>
                    <SliderNavigation
                      disabled={activeSlide === 0}
                      onClick={handlePrevious}
                      type={'previous'}
                    />
                  </PreviousButtonWrapper>

                  <NextButtonWrapper>
                    <SliderNavigation
                      disabled={activeSlide === total - 6}
                      onClick={handleNext}
                      type={'next'}
                    />
                  </NextButtonWrapper>
                </>
              )}
            </Wrapper>
          )}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

/**
 * Export `CarouselBox` component.
 */

export default CarouselBox;


/**
 * Module dependencies.
 */

import React, { ReactElement, ReactNode, forwardRef, useEffect } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode,
  className?: string
};

/**
 * `GlobeElement` styled component.
 */

const GlobeWrapper = styled.div`
  position: relative;
  width: 100%;
`;

/**
 * `Globe` component.
 */

const Globe = forwardRef((props: Props, ref: any): ReactElement => {
  useEffect(() => {
    const handleLoad = () => {
      const script = document.createElement('script');

      script.src = '/static/js/miniature.earth.js';
      document.body.appendChild(script);
    };

    if (document.readyState === 'complete') {
      handleLoad();

      return;
    }

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <GlobeWrapper
      {...props}
      ref={ref}
    />
  );
});

/**
 * `Globe` display name.
 */

Globe.displayName = 'Globe';

/**
 * Export `Globe` page.
 */

export default Globe;


/**
 * Module depenencies.
 */

import { Slide } from 'src/components/core/slider';
import { Subtitle } from 'src/components/core/typography';
import {
  isExternalUrl,
  media,
  units,
  useBreakpoint
} from '@untile/react-components';

import { theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Carousel from 'src/components/core/carousel';
import FadeInAnimation from 'src/components/core/animations/fade-in';
import FadeInLeftAnimation from 'src/components/core/animations/fade-in-left';
import Image from 'src/components/core/image';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import SliderNavigation from 'src/components/core/slider/slider-navigation';
import map from 'lodash/map';
import size from 'lodash/size';
import styled from 'styled-components';

/**
 * Constants.
 */

const LabelHeight = units(4.5);

/**
 * Export `Banner` type.
 */

export type Banner = {
  image: string,
  label: string,
  linkUrl?: string | null
};

/**
 * `Props` type.
 */

type Props = {
  banners: Banner[],
  canTrigger: boolean,
  className?: string,
  isAnimated: boolean,
  isVisible: boolean
};

/**
 * `Section` component.
 */

const Section = styled.section`
  position: relative;

  ${media.min('md')`
    height: calc(100vh - ${theme('dimensions.navbarHeight')}px);
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  transition: transform 5s ease;
`;

/**
 * `SlideLink` styled component.
 */

const SlideLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalUrl(href) && RouterLink || 'a'
}))`
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover {
    ${StyledImage} {
      transform: scale(1.1);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;

  ${media.max('md')`
    padding-bottom: 65%;
  `}

  ${media.min('md')`
    height: calc(100% - ${LabelHeight});
  `}
`;

/**
 * `LabelWrapper` styled component.
 */

const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${LabelHeight};
  justify-content: center;
  text-align: center;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Subtitle)`
  line-height: 15px;
`;

/**
 * `PreviousButtonWrapper` styled component.
 */

const PreviousButtonWrapper = styled.div`
  left: 0;
  position: absolute;
  top: calc(50% - 18px);
  transform: translateY(-50%);
  z-index: 9;

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `NextButtonWrapper` styled component.
 */

const NextButtonWrapper = styled(PreviousButtonWrapper)`
  left: auto;
  right: 0;
`;

/**
 * Carousel config.
 */

const carouselConfig = {
  breakpoints: {
    768: {
      centeredSlides: true,
      slidesPerView: 1.3
    }
  },
  freeMode: true,
  loop: true,
  slidesPerView: 1.1
};

/**
 * `BannerSection` component.
 */

const BannerSection = (props: Props): ReactElement | null => {
  const { banners, canTrigger, className, isAnimated, isVisible } = props;
  const isMobile = useBreakpoint('md', 'max');
  const { locale } = useRouter();
  const sliderRef = useRef<any>();
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const total = size(banners);
  const handlePrevious = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  }, []);

  const handleNext = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  }, []);

  return (
    <FadeInLeftAnimation
      canTrigger={canTrigger}
      isAnimated={isAnimated}
      isVisible={isVisible}
      options={{
        distance: '100%',
        transitionDelay: 0.5,
        transitionTime: 1
      }}
    >
      <Section className={className}>
        <Carousel
          activeSlide={activeSlide}
          carouselConfig={carouselConfig}
          containerModifierClass={'homepage-banner'}
          onSetActiveSlide={setActiveSlide}
          ref={sliderRef}
          speed={1000}
        >
          {map(banners, (banner: Banner, index: number) => {
            const { image, label, linkUrl } = banner;

            return (
              <Slide key={index}>
                {linkUrl ? (
                  <SlideLink
                    href={linkUrl}
                    {...linkUrl && !isExternalUrl(linkUrl) && { locale }}
                  >
                    <ImageWrapper>
                      <StyledImage
                        alt={label}
                        layout={'fill'}
                        objectFit={'cover'}
                        src={image}
                      />
                    </ImageWrapper>

                    <LabelWrapper>
                      <Label>
                        {label}
                      </Label>
                    </LabelWrapper>
                  </SlideLink>
                ) : (
                  <>
                    <ImageWrapper>
                      <StyledImage
                        alt={label}
                        layout={'fill'}
                        objectFit={'cover'}
                        src={image}
                      />
                    </ImageWrapper>

                    <LabelWrapper>
                      <Label>
                        {label}
                      </Label>
                    </LabelWrapper>
                  </>
                )}
              </Slide>
            );
          })}
        </Carousel>

        {total > 1 && !isMobile && (
          <FadeInAnimation
            canTrigger={canTrigger}
            isAnimated={isAnimated}
            isVisible={isVisible}
            options={{
              transitionDelay: 0.5,
              transitionTime: 1
            }}
          >
            <PreviousButtonWrapper>
              {total > 1 && (
                <SliderNavigation
                  aria-label={'Previous'}
                  onClick={handlePrevious}
                  type={'previous'}
                />
              )}
            </PreviousButtonWrapper>

            <NextButtonWrapper>
              <SliderNavigation
                aria-label={'Next'}
                onClick={handleNext}
                type={'next'}
              />
            </NextButtonWrapper>
          </FadeInAnimation>
        )}
      </Section>
    </FadeInLeftAnimation>
  );
};

/**
 * Export `BannerSection` component.
 */

export default BannerSection;


/**
 * Module dependencies.
 */

import { Fullpage, Section } from 'src/components/fullpage';
import { Homepage } from 'src/types/homepage';
import { HomepageProjects } from 'src/types/homepage-projects';
import { Testimonials } from 'src/types/testimonials';
import { fetchBanner } from 'src/api/banners/fetch-banner';
import { fetchHomepage } from 'src/api/homepage/fetch-homepage';
import { fetchHomepageProjects } from 'src/api/homepage/fetch-homepage-projects';
import { units } from '@untile/react-components';
import { useVerticalBarContext } from 'src/context/vertical-bar/context';
import BannerSection, { Banner } from 'src/components/sections/banner-section';
import ClientsSection from 'src/components/sections/clients-section';
import Footer from 'src/components/footer';
import GlobeSection from 'src/components/sections/globe-section';
import Loading from 'src/components/loading';
import Metatags from 'src/components/core/metatags';
import NumbersSection from 'src/components/sections/numbers-section';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import TestimonialsSection from 'src/components/sections/testimonials-section';
import TextImageSection from 'src/components/sections/text-image-section';
import VideoSection from 'src/components/sections/video-section';
import compact from 'lodash/compact';
import map from 'lodash/map';
import styled from 'styled-components';
import useBanner from 'src/api/banners/use-banner';
import useHomepage from 'src/api/homepage/use-homepage';
import useHomepageProjects from 'src/api/homepage/use-homepage-projects';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  banners: Banner[],
  data: Homepage,
  projects: HomepageProjects[]
};

/**
 * `FooterSection` styled component.
 */

const FooterSection = styled(Section)`
  padding: 0 !important;
`;

/**
 * `StyledClientsSection` styled component.
 */

const StyledClientsSection = styled(Section)`
  padding: ${units(3.5)} 0 !important;
`;

/**
 * `Home` page.
 */

const Home = (props: Props): ReactElement => {
  const { translate } = useTranslate();
  const {
    data: bannersData,
    isLoading: isLoadingBanners,
    isSuccess: isSuccessBanners
  } = useBanner<Banner[]>({ initialData: props?.banners });

  const {
    data,
    isLoading: isLoadingData,
    isSuccess: isSuccessData
  } = useHomepage<Homepage>({ initialData: props?.data });

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isSuccess: isSuccessProjects
  } = useHomepageProjects<HomepageProjects[]>({ initialData: props?.projects });

  const isLoading = isLoadingBanners || isLoadingData || isLoadingProjects;
  const sectionsTitles = useMemo(() => compact([
    translate('common:verticalBar.landmarkingPartners'),
    translate('common:verticalBar.ourProjects'),
    data?.section1Title,
    data?.section2Title,
    translate('common:verticalBar.landmarks'),
    translate('common:verticalBar.ourNumbers'),
    translate('common:verticalBar.testimonials'),
    translate('common:verticalBar.testimonials')
  ]), [data?.section1Title, data?.section2Title, translate]);

  const [visibleSection, setVisibleSection] = useState<number>(0);
  const { setTitle } = useVerticalBarContext();
  const handleAfterLoad = useCallback((_, destination) => {
    setTitle(sectionsTitles[destination.index]);
  }, [sectionsTitles, setTitle]);

  const handleOnLeave = useCallback((_, destination) => {
    setVisibleSection(destination.index);
    setTitle(sectionsTitles[destination.index]);
  }, [sectionsTitles, setTitle]);

  return (
    <>
      <Metatags
        description={data?.descriptionSeo}
        pageTitle={data?.titleSeo ?? translate('metatags:homepage.title')}
        title={data?.titleSeo ?? translate('metatags:homepage.title')}
      />

      <Loading active={isLoading} />

      <Fullpage
        options={{
          afterLoad: handleAfterLoad,
          normalScrollElements: '#globe-wrapper',
          onLeave: handleOnLeave
        }}
      >
        {() => (
          <>
            <Section>
              <VideoSection
                imageUrl={'/static/images/home-video-image.jpg'}
                title={translate('common:homepage.sectionVideo.title')}
                videoUrl={'/static/videos/home-video.mp4'}
              />
            </Section>

            {isSuccessBanners && (
              <Section>
                <BannerSection
                  banners={bannersData}
                  canTrigger={visibleSection === 1}
                  isAnimated={false}
                  isVisible={visibleSection === 1}
                />
              </Section>
            )}

            {isSuccessData && (
              <>
                <Section>
                  <TextImageSection
                    canTrigger={visibleSection === 2}
                    description={data?.section1Description}
                    imageUrl={data?.section1Image}
                    isAnimated={false}
                    isVisible={visibleSection === 2}
                    title={data?.section1Title}
                  />
                </Section>

                <Section>
                  <TextImageSection
                    canTrigger={visibleSection === 3}
                    description={data?.section2Description}
                    imageUrl={data?.section2Image}
                    isAnimated={false}
                    isReversed
                    isVisible={visibleSection === 3}
                    title={data?.section2Title}
                  />
                </Section>
              </>
            )}

            {isSuccessProjects && (
              <Section>
                <GlobeSection
                  canTrigger={visibleSection === 4}
                  projectsLocations={projects}
                />
              </Section>
            )}

            {isSuccessData && (
              <>
                <Section>
                  <NumbersSection
                    blocks={data?.homeBlocks}
                    canTrigger={visibleSection === 5}
                    isAnimated={false}
                    isVisible={visibleSection === 5}
                  />
                </Section>

                {map(data?.homeTestimonials, ({
                  description,
                  image,
                  label
                }: Testimonials, index: number) => (
                  <Section key={index}>
                    <TestimonialsSection
                      canTrigger={visibleSection === index + 6}
                      description={description}
                      image={image}
                      isAnimated={false}
                      isVisible={visibleSection === index + 6}
                      label={label}
                    />
                  </Section>
                ))}

                <StyledClientsSection className={'fp-auto-height'}>
                  <ClientsSection clients={data?.homeClients} />
                </StyledClientsSection>
              </>
            )}

            <FooterSection className={'fp-auto-height'}>
              <Footer />
            </FooterSection>
          </>
        )}
      </Fullpage>
    </>
  );
};

/**
 * Export `getServerSideProps`.
 */

export async function getServerSideProps({ locale }) {
  const banners = await fetchBanner({ locale, ssr: true });
  const data = await fetchHomepage({ locale, ssr: true });
  const projects = await fetchHomepageProjects({ locale, ssr: true });

  return {
    props: {
      ...banners ? banners : {},
      ...data ? data : {},
      ...projects ? projects : {}
    }
  };
}

/**
 * Export `Home` page.
 */

export default Home;

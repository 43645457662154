
/**
 * Module dependencies.
 */

import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import concat from 'lodash/concat';

/**
 * Globe settings.
 */

const globeSettings = {
  autoRotate: false,
  light: 'sun',
  lightAmbience: 0.6,
  lightIntensity: 0.5,
  location: {
    lat: 39.399872,
    lng: -8.224454
  },
  mapLandColor: '#bababa',
  mapSeaColor: '#d9d9d9',
  sunDirection: { x: 0.6, y: 1 }, // eslint-disable-line id-length
  zoom: 1.25,
  zoomable: false
};

/**
 * Marker settings.
 */

const markerSettings = {
  color: '#7ed2d7',
  flatShading: true,
  hotspot: true,
  lookAt: true,
  mesh: ['Pin3'],
  offset: 0.05,
  scale: 0.35
};

/**
 * Export `Location` type.
 */

export type Location = {
  lat: number,
  lng: number
};

/**
 * Export `Marker` type.
 */

export type Marker = {
  location: Location,
  name: string
};

/**
 * `Options` type.
 */

type Options = {
  hasInfoBox?: boolean,
  markers: Marker[]
};

/**
 * `ObserverResult` type.
 */

type ObserverResult = {
  activeMarkerIndex: number | null,
  element: any,
  instance: any,
  markers: any,
  onClickMarker: (location: Location, index: number) => void,
  setActiveMarker: Dispatch<SetStateAction<number | null>>
};

/**
 * `useGlobe3d` hook.
 */

function useGlobe3d(options: Options): ObserverResult {
  const ref = useRef();
  const [instance, setInstance] = useState<any>();
  const [markers, setMarkers] = useState([]);
  const [activeMarker, setActiveMarker] = useState<number | null>();
  const handleClickMarker = useCallback((location: Location, index: number) => {
    if (!instance) {
      return;
    }

    instance.goTo(location, { duration: 250, relativeDuration: 70 });
    setActiveMarker(index);
  }, [instance]);

  useEffect(() => {
    const handleEarthjsload = () => {
      if (ref?.current) {
        // @ts-ignore
        const globe = new Earth(ref?.current, globeSettings);

        setInstance(globe);

        globe.addEventListener('ready', () => {
          options.markers.map(({ location }, index: number) => {
            const marker = globe.addMarker({
              ...markerSettings,
              location
            });

            setMarkers(prev => concat(prev, marker));

            marker.addEventListener('click', () => {
              globe.goTo(location, { duration: 250, relativeDuration: 70 });
              setActiveMarker(index);
            });
          });
        });

        if (options.hasInfoBox) {
          globe.addEventListener('dragstart', () => {
            setActiveMarker(null);
          });
        }
      }
    };

    window.addEventListener('earthjsload', handleEarthjsload);

    return () => {
      window.removeEventListener('earthjsload', handleEarthjsload);
    };
  }, [markers, options.hasInfoBox, options.markers]);

  useEffect(() => {
    if (activeMarker >= 0) {
      markers.map((marker, index) => {
        if (index !== activeMarker) {
          marker.color = '#7ed2d7';

          return;
        }

        marker.animate('color', '#a45347', { duration: 500 });
      });
    }
  }, [activeMarker, markers]);

  return {
    activeMarkerIndex: activeMarker,
    element: ref,
    instance,
    markers,
    onClickMarker: handleClickMarker,
    setActiveMarker
  };
}

/**
 * Export `useGlobe3d` hook.
 */

export default useGlobe3d;


/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchHomepageProjectDetail } from './fetch-homepage-project-detail';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  id: string,
  initialData?: P | null
};

/**
 * Action type.
 */

const actionType = ({ id, locale }): string => {
  return `${locale}-homepage-project-detail-${id}`;
};

/**
 * `useHomepageProjectDetail` hook.
 */

function useHomepageProjectDetail<P>(options: Options<P>): Props<P> {
  const { locale } = useRouter();
  const variables = useMemo(() => ({
    id: options?.id,
    locale
  }), [locale, options?.id]);
  
  const result = useQuery([actionType(variables), variables], () => {
    return fetchHomepageProjectDetail(variables);
  }, {
    initialData: options?.initialData
  });

  return result;
}

/**
 * Export `useHomepageProjectDetail` hook.
 */

export default useHomepageProjectDetail;


/**
 * Module dependencies.
 */

import { Swiper, SwiperSlide } from 'swiper/react';
import { units } from '@untile/react-components';
import Image from 'src/components/core/image';
import React, {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import SwiperCore, {
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  SwiperOptions,
  Thumbs
} from 'swiper';

import merge from 'lodash/merge';
import styled, { createGlobalStyle } from 'styled-components';
import swiperStyles from 'swiper/swiper-bundle.css';

/**
 * Load swiper components.
 */

SwiperCore.use([
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs
]);

/**
 * Export `CarouselProps` interface.
 */

export interface CarouselProps extends SwiperOptions {
  activeSlide: number;
  carouselConfig?: any;
  children: ReactNode;
  className?: string;
  onClick?: Dispatch<SetStateAction<boolean>>;
  onSetActiveSlide: Dispatch<SetStateAction<number>>;
  ref?: any;
  showArrows?: boolean;
  showScrollbar?: boolean;
  thumbsItems?: any;
  totalItems?: number;
}

/**
 * `GlobalStyle` component.
 */

const GlobalStyle = createGlobalStyle`
  ${swiperStyles}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  > div {
    height: auto;
  }

  .swiper-container {
    height: 100%;
    margin: 0;
  }
  
  .swiper-container-thumbs {
    height: auto;
    margin-top: ${units(1)};
  }
  
  .swiper-slide {
    height: auto;
  }
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  transition: transform 5s ease;
`;

/**
 * `SlideItemThumb` styled component.
 */

const SlideItemThumb = styled.div`
  cursor: pointer;
  height: 0;
  overflow: hidden;
  padding-bottom: 80%;
  position: relative;
`;

/**
 * Default carousel config.
 */

const defaultCarouselConfig = () => {
  return {
    autoplay: false,
    spaceBetween: 8
  };
};

/**
 * Default carousel thumbs config.
 */

const defaultCarouselThumbsConfig = {
  breakpoints: {
    768: {
      slidesPerView: 4
    }
  },
  slidesPerView: 3,
  spaceBetween: 8
};

/**
 * Export `Slide` component.
 */

export const Slide = SwiperSlide;

/**
 * `Carousel` component.
 */

const Carousel: FC<CarouselProps> = forwardRef<any, CarouselProps>((props: CarouselProps, ref: any): ReactElement => {
  const {
    carouselConfig,
    children,
    className,
    containerModifierClass,
    onClick,
    onSetActiveSlide,
    speed,
    thumbsItems,
    ...rest } = props;

  const [windowWidth, setWindowWidth] = useState<number>();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const modifierClassName = containerModifierClass ? `${containerModifierClass}-` : '';
  const normalizedCarouselConfig = useMemo(() => {
    if (!carouselConfig) {
      return null;
    }

    const defaultConfig = defaultCarouselConfig();

    return merge({}, carouselConfig, defaultConfig);
  }, [carouselConfig]);

  const handleSlideChange = useCallback(({ activeIndex }) => {
    onSetActiveSlide(activeIndex);
  }, [onSetActiveSlide]);

  const handleWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);

    if (ref && ref.current) {
      ref.current.swiper.update();
    }
  }, [ref]);

  const onHoverSlide = useCallback(() => {
    if (ref && ref.current && normalizedCarouselConfig.autoplay) {
      ref.current.swiper.autoplay.stop();
    }
  }, [ref, normalizedCarouselConfig]);

  const onHoverOutSlide = useCallback(() => {
    if (ref && ref.current && normalizedCarouselConfig.autoplay) {
      ref.current.swiper.autoplay.start();
    }
  }, [ref, normalizedCarouselConfig]);

  useEffect(() => {
    if (!windowWidth) {
      setWindowWidth(window.innerWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  return (
    <Wrapper
      className={className}
      onMouseEnter={() => onHoverSlide()}
      onMouseLeave={() => onHoverOutSlide()}
    >
      <GlobalStyle />

      <Swiper
        {...normalizedCarouselConfig}
        onClick={onClick}
        onSlideChange={handleSlideChange}
        ref={ref}
        scrollbar={{
          dragClass: `${modifierClassName}scrollbar-drag`,
          el: `.${modifierClassName}scrollbar`,
          hide: false
        }}
        speed={speed ?? 750}
        thumbs={{ swiper: thumbsSwiper }}
        {...rest}
      >
        {children}
      </Swiper>

      {thumbsItems && (
        <Swiper
          {...defaultCarouselThumbsConfig}
          onSwiper={setThumbsSwiper}
          watchSlidesProgress
        >
          {thumbsItems.map((image, index: number) => (
            <Slide key={index}>
              <SlideItemThumb>
                <StyledImage
                  alt={image}
                  layout={'fill'}
                  objectFit={'cover'}
                  src={image}
                />
              </SlideItemThumb>
            </Slide>
          ))}
        </Swiper>
      )}
    </Wrapper>
  );
});

/**
 * `Carousel` display name.
 */

Carousel.displayName = 'Carousel';

/**
 * Export `Carousel` component.
 */

export default Carousel;

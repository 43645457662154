
/**
 * Module dependencies.
 */

import { Svg, color, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import React, { ReactElement } from 'react';
import arrowBottomIcon from 'src/assets/svg/arrow-down-2.svg';
import arrowLeftIcon from 'src/assets/svg/arrow-left-2.svg';
import arrowRightIcon from 'src/assets/svg/arrow-right-2.svg';
import arrowTopIcon from 'src/assets/svg/arrow-up-2.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  disabled?: boolean,
  isVerticalNavigation?: boolean,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void,
  ref?: any,
  type: 'next' | 'previous'
};

/**
 * `ArrowButton` styled component.
 */

const ArrowButton = styled.button<{ disabled: boolean }>`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${color('black')};
  cursor: pointer;
  outline: none;
  padding: ${units(3.5)};
  transition: color ${theme('animations.defaultTransition')};

  &:hover {
    color: ${color('grey500')};
  }

  ${ifProp('disabled', css`
    display: none;
  `)}

  &.disabled {
    display: none;
  }
`;

/**
 * `SliderNavigation` component.
 */

const SliderNavigation = (props: Props): ReactElement => {
  const { disabled, isVerticalNavigation, type, ...rest } = props;

  return (
    <ArrowButton
      {...rest}
      disabled={disabled}
    >
      {isVerticalNavigation ? (
        <Svg
          icon={type === 'next' ? arrowBottomIcon : arrowTopIcon}
          size={units(3)}
        />
      ) : (
        <Svg
          icon={type === 'next' ? arrowRightIcon : arrowLeftIcon}
          size={units(3)}
        />
      )}
    </ArrowButton>
  );
};

/**
 * Export `SliderNavigation` component.
 */

export default SliderNavigation;

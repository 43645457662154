
/**
 * Module dependencies.
 */

import {
  RawHtml,
  Svg,
  Type,
  color,
  media,
  units,
  useBreakpoint
} from '@untile/react-components';

import { theme } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import React, { ReactElement } from 'react';
import VideoPlayer from 'src/components/video-player';
import scrollDown from 'src/assets/svg/scroll-down.svg';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  imageUrl: string,
  title?: string,
  videoUrl: string
};

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

/**
 * `StyledVideoPlayer` styled component.
 */

const StyledVideoPlayer = styled(VideoPlayer)`
  height: calc(100vh - ${theme('dimensions.navbarHeightMobile')}px);

  ${media.min('md')`
    height: calc(100vh - ${theme('dimensions.navbarHeight')}px);
  `}
`;

/**
 * `VideoTitle` styled component.
 */

const VideoTitle = styled(Type.H1)`
  margin-bottom: ${units(2)};
  text-align: right;

  ${media.min('md')`
    margin-bottom: ${units(6)};
  `}
`;

/**
 * `ScrollDownWrapper` styled component.
 */

const ScrollDownWrapper = styled.div`
  align-items: center;
  bottom: ${units(3.5)};
  color: ${color('white')};
  display: flex;
  flex-direction: column;
  grid-gap: ${units(1)};
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
`;

/**
 * `ScrollDownLabel` styled component.
 */

const ScrollDownLabel = styled(Type.Small).attrs({ size: 'xxSmall' })`
  letter-spacing: 2.86px;
  line-height: 15px;
  text-transform: lowercase;
`;

/**
 * `VideoSection` component.
 */

const VideoSection = (props: Props): ReactElement => {
  const { imageUrl, title, videoUrl } = props;
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('md', 'max');

  return (
    <>
      <StyledVideoPlayer
        image={imageUrl}
        playing
        url={videoUrl}
        volume={1}
      />

      {title && (
        <StyledContainer>
          <RawHtml element={VideoTitle}>
            {title}
          </RawHtml>
        </StyledContainer>
      )}

      {!isMobile && (
        <ScrollDownWrapper>
          <RawHtml element={ScrollDownLabel}>
            {translate('common:homepage.sectionVideo.scrollDown')}
          </RawHtml>

          <Svg
            icon={scrollDown}
            size={units(4)}
          />
        </ScrollDownWrapper>
      )}
    </>
  );
};

/**
 * Export `VideoSection` component.
 */

export default VideoSection;

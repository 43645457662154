
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchHomepage } from './fetch-homepage';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  initialData?: P | null
};

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-homepage`;
};

/**
 * `useHomepage` hook.
 */

function useHomepage<P>({ initialData }: Options<P>): Props<P> {
  const { locale } = useRouter();
  const variables = useMemo(() => ({ locale }), [locale]);
  const result = useQuery([actionType(variables), variables], () => {
    return fetchHomepage(variables);
  }, {
    initialData
  });

  return result;
}

/**
 * Export `useHomepage` hook.
 */

export default useHomepage;


/**
 * Module dependencies.
 */

import { Box, Svg, Type, media, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import FadeInAnimation from 'src/components/core/animations/fade-in';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import React, { ReactElement } from 'react';
import iconQuote from 'src/assets/svg/quote.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  canTrigger: boolean,
  description: string,
  image: string,
  isAnimated: boolean,
  isVisible: boolean,
  label: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;

  ${media.min('md')`
    height: calc(100vh - ${theme('dimensions.navbarHeight')}px);
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;

  ${media.max('md')`
    padding-bottom: 65%;
  `}

  ${media.min('md')`
    height: 100%;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  bottom: 0;
  display: grid;
  grid-gap: ${units(3)} ${units(1)};
  grid-template-areas:
    'iconLeft quote  iconRight'
    '.        author .';
  grid-template-columns: max-content minmax(min-content, 275px) max-content;
  grid-template-rows: repeat(2, max-content);
  left: 0;
  padding-top: ${units(6)};
  position: absolute;
  right: 0;
  top: 0;
`;

/**
 * `StyledSvg` styled component.
 */

const StyledSvg = styled(Svg)<{ isReversed?: boolean }>`
  ${ifProp('isReversed', css`
    transform: rotateY(180deg);
  `)}
`;

/**
 * `Quote` styled component.
 */

const Quote = styled(Type.Paragraph)`
  grid-area: quote;
  text-align: justify;
`;

/**
 * `TestimonialsSection` component.
 */

const TestimonialsSection = (props:Props):ReactElement => {
  const { canTrigger, description, image, isAnimated, isVisible, label } = props;

  return (
    <Wrapper>
      <FadeInUpAnimation
        canTrigger={canTrigger}
        className={'full-height'}
        isAnimated={isAnimated}
        isVisible={isVisible}
        options={{
          distance: '100%',
          transitionDelay: 0.5,
          transitionTime: 1
        }}
      >
        <ImageWrapper>
          <Image
            alt={label}
            layout={'fill'}
            objectFit={'cover'}
            src={image}
          />
        </ImageWrapper>
      </FadeInUpAnimation>

      <FadeInAnimation
        canTrigger={canTrigger}
        isAnimated={isAnimated}
        isVisible={isVisible}
        options={{
          transitionDelay: 1.5,
          transitionTime: 1
        }}
      >
        <StyledContainer>
          <Box
            gridArea={'iconLeft'}
            paddingTop={units(0.5)}
          >
            <StyledSvg
              icon={iconQuote}
              isReversed
              size={units(2.5)}
            />
          </Box>

          <Quote>
            {description}
          </Quote>

          <Box
            alignItems={'flex-end'}
            display={'flex'}
            gridArea={'iconRight'}
            paddingBottom={units(0.5)}
          >
            <StyledSvg
              icon={iconQuote}
              size={units(2.5)}
            />
          </Box>

          <Type.Paragraph
            gridArea={'author'}
            size={'small'}
          >
            {label}
          </Type.Paragraph>
        </StyledContainer>
      </FadeInAnimation>
    </Wrapper>
  );
};

/**
 * Export `TestimonialsSection` component.
 */

export default TestimonialsSection;
